<template>
  <a-modal
    title="意见处理"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 状态 -->
            <a-form-item 
              label="状态" 
              :labelCol="labelCol" 
              :wrapperCol="wrapperCol" 
              has-feedback
            >
              <a-radio-group 
                name="radioGroup" 
                v-decorator="['isRead', { rules: [{ required: true, message: '请选择！' }] }]"
              >
                <a-radio :value="1">未读</a-radio>
                <a-radio :value="2">已读&nbsp;&nbsp;&nbsp;</a-radio>
              </a-radio-group>
            </a-form-item>
            <!-- 消息内容 -->
            <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-textarea
                v-decorator="['remark']"
                placeholder="请输入备注"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { feedDetail, feedRead } from '@/api/modular/mallLiving/agreeFeed'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:''
    }
  },

  methods: {
    // 初始化方法
    action(record) {
      this.visible = true
      this.id = record.id
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
            feedRead({ id:this.id, ...values }).then((res) => {
              if(res.success){
                this.$message.success("意见处理成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>